<template>
  <div class="loadPhoto">
  </div>
</template>

<script>
/* eslint-disable no-console */

import CryptoHelper from '@/helpers/CryptoHelper';
import ServiceHelper from '@/helpers/ServiceHelper';

export default {
  name: 'LoadAttachmentView',
  components: {
  },
  props: ['attachmentId'],
  mounted() {
    this.openFile(this.attachmentId);
  },
  methods: {
    async prepareFile(attachmentId) {
      const data = await ServiceHelper.loadDataFromBackend(`attachment/${attachmentId}`);
      const encData = data.payload;
      const decData = JSON.parse(CryptoHelper.decryptToBuffer(encData.content));
      const type = encData.type === 'VOICE' ? 'audio/wav' : 'image/jpeg';
      // console.log(attachmentId, encData.type, type);
      const blob = new Blob([Buffer.from(decData.content, 'base64')], { type });
      const objUrl = URL.createObjectURL(blob);
      return objUrl;
    },
    async openFile(attachmentId) {
      try {
        // const objUrl = await this.prepareFile(attachmentId);

        const objUrl = await this.prepareFile(attachmentId);
        const link = document.createElement('a');
        link.setAttribute('href', objUrl);
        link.setAttribute('download', attachmentId);
        document.body.appendChild(link); // Required for FF
        link.click(); // This will download the data file
        link.remove();

        // console.log(objUrl);
        // window.location.href = objUrl;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
